<script>
import { userService } from "../../../helpers/user.service";
import Swal from "sweetalert2";

import Layout from "../../layouts/main";

export default {
  components: {
    Layout,
  },
  data() {
    return {
      Roles: {},
      Access: {},
      NewRole: {
        rolename: "",
        data: {},
      },
      showmodal: false,
      swalWithBootstrapButtons: Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger ml-2",
        },
        buttonsStyling: false,
      }),
    };
  },
  beforeMount() {
    userService.getRoles().then((data) => {
      delete data.message.admin;
      this.Roles = data.message;
    });
  },
  methods: {
    CheckAccessOnce(capp) {
      return this.$CheckAccessOnce(capp);
    },
    ShowEdit(role) {
      this.showmodal = true;
      userService.getAccess(role).then((data) => {
        this.NewRole.data = data.message;
        this.NewRole.rolename = role;
      });
    },
    // eslint-disable-next-line no-unused-vars
    handleSubmit(e) {
      const Role = this.NewRole.rolename;
      const AccessData = this.NewRole.data;
      userService.updateRole(Role, AccessData).then((data) => {
        this.swalWithBootstrapButtons.fire({
          text: data.message,
          icon: data.err,
          confirmButtonText: "تایید",
        });
      });
      this.showmodal = false;
    },
  },
};
</script>

<template>
  <Layout>
    <div class="row">
      <div
        v-for="(item, index) in Roles"
        :key="index"
        :value="item"
        class="col-lg-3 col-md-3 col-sm-4"
      >
        <b-card
          header-class="bg-transparent border-primary"
          class="border border-primary"
        >
          <template v-slot:header>
            <h5 class="my-0 text-primary d-flex justify-content-between">
              {{ item }}
              <i class="mdi mdi-file-document-edit-outline ml-3"></i>
            </h5>
          </template>
          <p class="card-text">
            <button
              @click="ShowEdit(index)"
              class="btn btn-primary w-md waves-effect waves-light col-12"
            >
              ویرایش دسترسی {{ item }}
            </button>
          </p>
        </b-card>
      </div>
    </div>
    <!-- Modal -->
    <b-modal
      id="modal-1"
      v-model="showmodal"
      title="ویرایش دسترسی"
      title-class="text-dark font-18"
      hide-footer
      scrollable
    >
      <form @submit.prevent="handleSubmit">
        <div class="form-group">
          <div
            v-for="(item, index) in $t('api.list')"
            :key="index"
            class="custom-control custom-checkbox"
          >
            <input
              type="checkbox"
              class="custom-control-input"
              v-model="NewRole.data[index]"
              :key="index"
              :id="index"
            />
            <label class="custom-control-label" :for="index"
              >&nbsp; {{ item }}</label
            >
          </div>
        </div>
        <div class="text-right">
          <button v-if="CheckAccessOnce('UpdateRole')" type="submit" class="btn btn-success">ذخیره</button>
          <b-button class="ml-1" variant="danger" @click="showmodal = false"
            >لغو</b-button
          >
        </div>
      </form>
    </b-modal>
    <!-- end modal -->
  </Layout>
</template>